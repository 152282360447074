<template>
  <div class="crypto crypto--table">
    <table class="crypto__table mb-5">
      <thead class="crypto__table-head">
        <tr>
          <th>
            <div class="crypto__table-col">
              <p class="txt">#</p>
            </div>
          </th>
          <th>
            <div class="crypto__table-col">
              <p class="txt">{{ cc.head[0] }}</p>
            </div>
          </th>
          <th>
            <div class="crypto__table-col">
              <p class="txt">{{ cc.head[1] }}</p>
            </div>
          </th>
          <th>
            <div class="crypto__table-col">
              <p class="txt">{{ cc.head[2] }}</p>
            </div>
          </th>
          <th>
            <div class="crypto__table-col">
              <p class="txt">{{ cc.head[3] }}</p>
            </div>
          </th>
          <th>
            <div class="crypto__table-col">
              <p class="txt">{{ cc.head[4] }}</p>
            </div>
          </th>
        </tr>
      </thead>
      <tbody class="crypto__table-body">
        <tr v-for="(val, key, i) in cList" :key="`crypto-item-${key}`">
          <td>
            <div class="crypto__table-col">
              <p class="txt fc-gray">{{ i + 1 }}</p>
            </div>
          </td>
          <td>
            <div class="crypto__table-col">
              <i class="crypto__table-icon me-3 me-md-4">
                <img :src="`/img/icons/crypto/${key}.svg`" alt="btc"/>
              </i>
              <div class="d-flex flex-column flex-sm-row">
                <p class="txt fw-500 me-3">{{ val }}</p>
                <p class="txt fc-gray">{{ key.toUpperCase() }}</p>
              </div>
            </div>
          </td>
          <td>
            <div class="crypto__table-col">
              <div class="d-flex flex-column align-items-end align-items-sm-start">
                <p class="txt fw-400 fc-gray" v-if="cryptoAssetData">$ {{ cryptoAssetData[key] ? cryptoAssetData[key].price : '--' }}</p>
                <template v-if="cryptoHistoryData && chartDataProp[key]">
                  <p class="txt" :class="[{'incr': chartDataProp[key].isGrow}, {'decr': !chartDataProp[key].isGrow}]">{{ chartDataProp[key].isGrow ? '+' : '-' }}{{ chartDataProp[key].change }}%</p>
                </template>
              </div>
            </div>
          </td>
          <td>
            <div class="crypto__table-col">
              <template v-if="cryptoHistoryData && chartDataProp[key]">
                <p class="txt" :class="[{'incr': chartDataProp[key].isGrow}, {'decr': !chartDataProp[key].isGrow}]">{{ chartDataProp[key].isGrow ? '+' : '-' }}{{ chartDataProp[key].change }}%</p>
              </template>
            </div>
          </td>
          <td>
            <div class="crypto__table-col" v-if="cryptoHistoryData">
              <div class="chart" style="position: relative; max-width: 138px; max-height: 42px">
                <comp-chart v-if="chartDataProp" :id="`table-${key}`" :chartDataProp="chartDataProp[key]" :bg="true" />
              </div>
            </div>
          </td>
          <td>
            <div class="crypto__table-col">
              <btn :data="cc.btn[0]" :theme="{type: 2, size: 'sm', width: 'fc'}" />
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <btn :data="cc.btn[1]" :theme="{type: 2, size: 'md', width: 'fc'}" class="mx-auto" />
  </div>
</template>
<script>
import Btn from '@/components/Btn'
import CompChart from '@/components/Chart'

export default {
  components: {
    Btn, CompChart
  },
  computed: {
    cc () {
      return this.$t('components.crypto.tbl')
    },
    cList () {
      return this.$store.state.cryptoListRow
    },
    cryptoAssetData () {
      return this.$store.state.cryptoAssetData
    },
    cryptoHistoryData () {
      return this.$store.state.cryptoHistoryData
    },
    chartDataProp () {
      const data = this.cryptoHistoryData
      const obj = {}
      for (const key in data) {
        obj[key] = { labels: [], data: [], change: 0, isGrow: true }
        data[key].forEach(item => {
          const label = item.label
          const price = item.price
          obj[key].labels.push(label)
          obj[key].data.push(price)
        })
        const last = +obj[key].data[13]
        const prev = +obj[key].data[12]
        obj[key].change = (last > prev ? (last / prev - 1) * 100 : (prev / last - 1) * 100).toFixed(2)
        obj[key].isGrow = last > prev
      }
      return obj
    }
  }
}
</script>
