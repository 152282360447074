<template>
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="crypto crypto--row">
          <div class="crypto__wrapper">
            <div class="crypto__row">
              <div class="crypto__item" v-for="(val, key) in cList" :key="`crypto-item-${key}`">
                <div class="crypto__item-body">
                  <div class="crypto__item-wrapper">
                    <div class="crypto__item-row">
                      <i class="crypto__item-icon">
                        <img :src="`/img/icons/crypto/${key}.svg`" :alt="key" />
                      </i>
                      <div class="cripto__item-info">
                        <p class="txt fw-400 fc-dark">{{ cList[key] }}</p>
                        <p class="txt fw-400 fc-dark" v-if="cryptoAssetData">$ {{ cryptoAssetData[key] ? cryptoAssetData[key].price : '--' }}</p>
                      </div>
                    </div>
                    <div class="crypto__item-row">
                      <div class="crypto__item-graph" v-if="cryptoHistoryData">
                        <div class="chart" style="position: relative; height: 42px">
                          <comp-chart v-if="chartDataProp" :id="key" :chartDataProp="chartDataProp[key]" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CompChart from '@/components/Chart'

export default {
  components: {
    CompChart
  },
  computed: {
    cList () {
      return this.$store.state.cryptoListRow
    },
    cryptoAssetData () {
      return this.$store.state.cryptoAssetData
    },
    cryptoHistoryData () {
      return this.$store.state.cryptoHistoryData
    },
    chartDataProp () {
      const data = this.cryptoHistoryData
      const obj = {}
      for (const key in data) {
        obj[key] = { labels: [], data: [] }
        data[key].forEach(item => {
          const label = item.label
          const price = item.price
          obj[key].labels.push(label)
          obj[key].data.push(price)
        })
      }
      return obj
    }
  }
}
</script>
