<template>
  <component :is="comp" />
</template>
<style lang="scss">
  @import '../assets/styles/components/crypto';
</style>
<script>
import row from '@/components/crypto/CryptoRow'
import tbl from '@/components/crypto/CryptoTable'
import about from '@/components/crypto/CryptoAbout'
import supported from '@/components/crypto/CryptoSupported'
export default {
  components: {
    row, tbl, about, supported
  },
  props: {
    comp: {
      required: true,
      type: String
    },
    asset: {
      required: false
    }
  },
  beforeCreate () {
    if (!this.$store.state.cryptoAssetData) this.$store.commit('getCeyptoAssetData')
    if (!this.$store.state.cryptoAssetData) this.$store.commit('getCryptoHistoryData')
  }
}
</script>
