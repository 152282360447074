<template>
  <div class="crypto crypto--supported">
    <div class="crypto__wrapper">
      <div class="crypto__row">
        <p class="txt fw-500 fc-dark ts-18" v-html="cc.tit" />
      </div>
      <div class="crypto__row">
        <div class="crypto__supported">
          <div class="crypto__supported-item" v-for="(item, i) in list" :key="`supported-item-${i}`">
            <img :src="`/img/icons/crypto/${item}.svg`" :alt="item" :title="$store.state.cryptoList[item]" v-if="item"/>
          </div>
        </div>
      </div>
      <div class="crypto__row py-2">
        <p class="txt fw-500 fc-dark ts-16 ta-center" v-html="cc.text" />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data: () => ({
    count: 7 * 4
  }),
  computed: {
    cc () {
      return this.$t('components.crypto.supported')
    },
    list () {
      const list = this.$store.state.cryptoList
      const arr = []
      for (const item in list) arr.push(item)
      if (arr.length < this.count) for (let i = arr.length; i < this.count; i++) arr.push('')
      return arr
    }
  }
}
</script>
