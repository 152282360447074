<template>
  <canvas :id="`chart-${id}`"></canvas>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import Chart from 'chart.js'

export default {
  name: 'PlanetChart',
  props: {
    id: {
      required: true
    },
    bg: {
      required: false,
      default: false
    },
    chartDataProp: {
      required: true,
      default: {
        labels: [0],
        data: [0]
      }
    }
  },
  data: () => ({}),
  computed: {
    chartData () {
      return {
        type: 'line',
        data: {
          labels: this.chartDataProp.labels,
          datasets: [
            {
              label: 'Number of Moons',
              data: this.chartDataProp.data,
              backgroundColor: +this.chartDataProp.data[0] > +this.chartDataProp.data[15] ? 'rgba(115, 199, 0, .1)' : 'rgba(255, 104, 56, .1)',
              borderColor: +this.chartDataProp.data[0] > +this.chartDataProp.data[15] ? '#73C700' : '#FF6838',
              borderWidth: 2,
              pointRadius: 0,
              tension: 0.4,
              fill: this.bg
            }
          ]
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          lineTension: 1,
          tooltips: {
            enabled: false
          },
          hover: {
            mode: null
          },
          legend: {
            display: false
          },
          scales: {
            yAxes: [{
              display: false,
              ticks: {
                min: Math.min(...this.chartDataProp.data) - (+(Math.min(...this.chartDataProp.data) / (Math.min(...this.chartDataProp.data).toString().length * 30)).toFixed(0)),
                max: +(Math.max(...this.chartDataProp.data) / (Math.max(...this.chartDataProp.data).toString().length * 30)).toFixed(0) + Math.max(...this.chartDataProp.data)
              }
            }],
            xAxes: [{
              display: false
            }]
          }
        }
      }
    }
  },
  mounted () {
    const ctx = document.getElementById(`chart-${this.id}`)
    // eslint-disable-next-line no-new
    new Chart(ctx, this.chartData)
  }
}
</script>
