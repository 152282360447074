<template>
  <div class="crypto crypto--about">
    <div class="crypto__wrapper">
      <div class="crypto__row">
        <div class="crypto__info">
          <i class="ico ico--crypto-about">
            <img :src="`/img/icons/crypto/${asset}.svg`" alt="">
          </i>
          <div>
            <p class="txt fw-500 ts-18 fc-dark">{{ name }}</p>
            <p class="txt fw-400 ts-14 fc-gray">{{ asset.toUpperCase() }}</p>
          </div>
        </div>
        <btn :data="cc.btn" :theme="{type: 5, size: 'sm', width: 'fc'}"/>
      </div>
      <div class="crypto__row">
        <p class="txt fw-400 ts-14 fc-dark" v-if="cryptoAssetData">{{ cc.txt[0] }}: {{ cryptoAssetData[asset] ? cryptoAssetData[key].price : '--' }}$</p>
        <p class="txt fw-400 ts-14 fc-dark">{{ cc.txt[1] }}: 0$</p>
        <p class="txt fw-400 ts-14 fc-dark">{{ `${cc.txt[2]}: ${info[asset].supply}` }}</p>
        <p class="txt fw-400 ts-14 fc-dark">{{ `${cc.txt[3]}: ${info[asset].author}` }}</p>
      </div>
    </div>
  </div>
</template>
<script>
import Btn from '@/components/Btn'
export default {
  components: {
    Btn
  },
  data: () => ({
    info: {
      btc: {
        author: 'Satoshi Nakamoto',
        supply: '21,000,000'
      },
      eth: {
        author: 'Vitalik Buterin',
        supply: 'Unlimited'
      },
      ada: {
        author: 'Charles Hoskinson',
        supply: '45,000,000,000'
      },
      mana: {
        author: 'Ari Meilich and Esteban Ordano',
        supply: '2,193,862,727'
      }
    }
  }),
  computed: {
    asset () {
      return this.$parent.asset
    },
    names () {
      return this.$store.state.cryptoList
    },
    name () {
      return this.names[this.asset]
    },
    cc () {
      return this.$t('components.crypto.about')
    },
    cryptoAssetData () {
      return this.$store.state.cryptoAssetData
    }
  }
}
</script>
